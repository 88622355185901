import { Calendar } from '@fullcalendar/core';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';

bootstrap5Plugin.themeClasses.bootstrap5.prototype.baseIconClass = 'fas'
bootstrap5Plugin.themeClasses.bootstrap5.prototype.iconOverridePrefix = 'fa-'
bootstrap5Plugin.themeClasses.bootstrap5.prototype.iconClasses = {
    close: 'fa-times',
    prev: 'fa-chevron-left',
    next: 'fa-chevron-right',
    prevYear: 'fa-angle-double-left',
    nextYear: 'fa-angle-double-right',
}
bootstrap5Plugin.themeClasses.bootstrap5.prototype.rtlIconClasses = {
    prev: 'fa-chevron-right',
    next: 'fa-chevron-left',
    prevYear: 'fa-angle-double-right',
    nextYear: 'fa-angle-double-left',
}

document.addEventListener('DOMContentLoaded', function() {
    let calendarEl = document.querySelector('#calendar');
    if(calendarEl) {
        let calendar = new Calendar(calendarEl, {
            plugins: [
                bootstrap5Plugin,
                dayGridPlugin,
                interactionPlugin,
                listPlugin,
                timeGridPlugin,
            ],
            locale: 'nl',
            themeSystem: 'bootstrap5',
            initialView: calendarEl.getAttribute('data-calendar-view'),
            height: 'auto',
            headerToolbar: (function() {
                if (calendarEl.getAttribute('data-calendar-view') == 'timeGridDay') {
                    return false;
                } else {
                    return {
                        start: 'prev,next today',
                        center: 'title',
                        end: 'timeGridWeek,dayGridMonth',
                    };
                }
            })(),
            slotMinTime: '08:00:00',
            slotMaxTime: '18:00:00',
            navLinks: true,
            firstDay: 1,
            weekNumbers: true,
            businessHours: true,
            nowIndicator: true,
            slotEventOverlap: false,
            editable: false,
            selectable: true,
            allDayText: '',
            buttonText: {
                today: 'Vandaag',
                month: 'Maand',
                week: 'Week',
                day: 'Dag',
                list: 'Lijst',
            },
            events: {
                url: '/getAgenda',
                method: 'post',
                extraParams: function() {
                    const params = new URLSearchParams(window.location.search);
                    return {
                        _token: CSRF_TOKEN,
                        search: params.get('search') ?? '',
                        user: params.get('user') ?? '',
                        view: calendarEl.getAttribute('data-calendar-view'),
                    };
                },
            },
            eventTimeFormat: {
                hour: '2-digit',
                minute: '2-digit',
            },
            eventDidMount: function(info) {
                $(info.el).popover({
                    html: true,
                    title: info.event.title,
                    content: function () {
                        $('.popover').popover('hide');
                        let content = '<ul class="list-unstyled mb-0">';

                        if (info.event.extendedProps.users) {
                            content += '<li><strong>Leden:</strong> ';
                            $.each(info.event.extendedProps.users, function (index, item) {
                                content += item.name + ' ';
                            });
                            content += '</li>';
                        }

                        if (info.event.extendedProps.location) {
                            content += '<li><strong>Locatie:</strong> ' + info.event.extendedProps.location + '</li>';
                        }

                        if (info.event.extendedProps.description) {
                            content += '<li><strong>Beschrijving:</strong><br>' + info.event.extendedProps.description + '</li>';
                        }

                        content += '</ul>';
                        return content;
                    },
                    animation: true,
                    placement: 'top',
                    trigger: 'click',
                    container: 'body',
                });
                $(document).click(function (event) {
                    if (!$(event.target).is('.popover *')) {
                        $(info.el).popover('hide');
                    }
                });
            },
            loading: function (loading) {
                if(!loading) {
                    $('#calendar').removeClass('loading');
                }
            }
        });
        calendar.render();
    }
});

